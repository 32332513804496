import React, { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ProtectedRoute as Route, routes } from '../lib/components/Auth';
import loadable from '@loadable/component';
import Layout from '../components/Layout';
import { inject, observer } from 'mobx-react';
import env from '../env';

const UserSettings = loadable(() => import('../components/UserSettings'));
const SystemUsersPage = loadable(() => import('../interfaces/systemusers/Page'));
const ChangesHistoryPage = loadable(() => import('../interfaces/changeshistory/ChangesHistoryPage'));
const Clients = loadable(() => import('../interfaces/client/ClientsRoutes'));
const RouteTest = loadable(() => import('../interfaces/route/RouteTestPage'));
const CheckNumbersRoutes = loadable(() => import('../interfaces/checknumbers/CheckNumbersRoutes'));
const CallsPage = loadable(() => import('../interfaces/calls/CallsPage'));
const SMSPage = loadable(() => import('../interfaces/sms/Page'));
const CrmRoutes = loadable(() => import('../interfaces/crm-integration/CrmRoutes'));
const TelephonyHistoryPage = loadable(() => import('../interfaces/telephony-history/TelephonyHistoryPage'));
const AuthorizationsHistoryPage = loadable(() => import('../interfaces/authorizationshistory/Page'));
const AntirobotPage = loadable(() => import('../interfaces/antirobot/Page'));
const UnbindingNumbersPage = loadable(() => import('../interfaces/unbindingnumbers/UnbindingNumbersPage'));
const DktNumbersRoutes = loadable(() => import('../interfaces/dktnumbers/DktNumbersRoutes'));
const DtRatioStatisticsRoutes = loadable(() => import('../interfaces/dt-ratio-statistics/Page'));
const Ct3RatioStatisticsRoutes = loadable(() => import('../interfaces/ct3-ratio-statistics/Page'));
const SipLinesPage = loadable(() => import('../interfaces/sip-lines/Page'));
const SipTrunksPage = loadable(() => import('../interfaces/sip-trunks/index'));
const ChargesComparePage = loadable(() => import('../interfaces/charges-compare/Page'));
const CounterAgentsPage = loadable(() => import('../interfaces/counteragents/Page'));
const RoutesStatisticsPage = loadable(() => import('../interfaces/routesstatistics/Page'));
const ContractTemplateCallPage = loadable(() => import('../interfaces/contract-template-call/Page'));
const ForcePage = loadable(() => import('../interfaces/force/Page'));
const AppUsersPage = loadable(() => import('../interfaces/app-users/Page'));
const PriceListsPage = loadable(() => import('../interfaces/price-lists/Page'));
const PriceListsAnalysisPage = loadable(() => import('../interfaces/price-lists-analysis/Page'));
const PriceListsComparePage = loadable(() => import('../interfaces/price-lists-compare/Page'));
const ZonesPage = loadable(() => import('../interfaces/zones/Page'));
const CountersPage = loadable(() => import('../interfaces/counters/Page'));
const NumberCapacityCountersPage = loadable(() => import('../interfaces/number-capacity-counters/Page'));
const PromoActionsPage = loadable(() => import('../interfaces/promo-actions/Page'));
const PromoActionsNfRoutes = loadable(() => import('../interfaces/promo-actions-nf/PromoActionsNfRoutes'));
const PromoCodesPage = loadable(() => import('../interfaces/promo-codes/Page'));
const NetsPage = loadable(() => import('../interfaces/nets/Page'));
const MTTPage = loadable(() => import('../interfaces/mtt/CallsPage'));
const LocationsPage = loadable(() => import('../interfaces/locations/Page'));
const PrefixTypesPage = loadable(() => import('../interfaces/number-type-prefixes/Page'));
const RegionPrefixesPage = loadable(() => import('../interfaces/region-prefixes/Page'));
const EventResending = loadable(() => import('../interfaces/event-resending/Page'));
const FeatureFlagsPage = loadable(() => import('../interfaces/feature-flags/Page'));
const InternationalCustomerDetailsPage = loadable(() => import('../interfaces/international-customer-details/Page'));
const InvoicesPage = loadable(() => import('../interfaces/invoices/Page'));
const ServiceAddressesPage = loadable(() => import('../interfaces/service-addresses/Page'));
const Partners = loadable(() => import('../interfaces/partners/PartnersRoutes'));
const CounteragentPage = loadable(() => import('../interfaces/counteragent-requisites/counteragent-list/Page'));
const BankRequisitesPage = loadable(() => import('../interfaces/counteragent-requisites/bank-requisites-list/Page'));
const BankRequisitesHistoryPage = loadable(
    () => import('../interfaces/counteragent-requisites/bank-requisites-history/Page')
);
const BankRequisitesEditPage = loadable(
    () => import('../interfaces/counteragent-requisites/bank-requisites-edit/Page')
);
const CounteragentRequisitesPage = loadable(
    () => import('../interfaces/counteragent-requisites/counteragent-requisites-list/Page')
);
const CounteragentRequisitesHistoryPage = loadable(
    () => import('../interfaces/counteragent-requisites/counteragent-requisites-history/Page')
);
const CounteragentRequisitesEditPage = loadable(
    () => import('../interfaces/counteragent-requisites/counteragent-requisites-edit/Page')
);
const AppCdrOutPrefixesPage = loadable(() => import('../interfaces/app-cdr-out-prefixes/Page'));
const WabaAccountsPage = loadable(() => import('../interfaces/waba-accounts/Page'));
const NotFinishedCallSessionsRoutes = loadable(() => import('../interfaces/not-finished-call-sessions/Routes'));
const TrialPage = loadable(() => import('../interfaces/app-trials/Page'));
const GeoIpsRoutes = loadable(() => import('../interfaces/geo-ips/GeoIpsRoutes'));
const NovofonTariffPlansPage = loadable(() => import('../interfaces/novofon/tariff-plans/Page'));
const NovofonCountersPage = loadable(() => import('../interfaces/novofon/counters/Page'));
const NovofonJobErrorsPage = loadable(() => import('../interfaces/novofon/job-errors/Page'));
const NovofonPromisedPayments = loadable(() => import('../interfaces/novofon/promised-payments/Page'));
const NovofonNumberCapacityCosts = loadable(() => import('../interfaces/novofon/number-capacity-costs/Page'));
const NovofonMovingCustomers = loadable(() => import('../interfaces/novofon/moving-customers/Page'));

class AppRoute extends Component {
    indexRedirect = () => {
        const { appStore } = this.props;
        const urls = ['clients', 'checknumbers'];
        const availableRoutes = routes.filter(r => appStore.checkInterfacePermission(r.permissions, r.env));
        for (let i = 0; i < urls.length; i++) {
            if (availableRoutes.hasOwnProperty(urls[i])) {
                return <Redirect to={'/' + urls[i]} />;
            }
        }
    };

    componentDidMount() {
        this.props.appStore.init();
    }

    render() {
        if (!this.props.appStore.isAuthenticated) {
            return <Redirect to="/login" />;
        }

        return this.props.appStore.isReady ? (
            <Layout>
                <Switch>
                    <Route path="/" exact render={this.indexRedirect} />
                    <Route path="/clients" component={Clients} />
                    <Route path="/app-users" exact component={AppUsersPage} />
                    <Route path="/system-users" exact component={SystemUsersPage} />
                    <Route path="/settings" exact component={UserSettings} />
                    <Route path="/changeshistory" exact component={ChangesHistoryPage} />
                    <Route path="/route/test" exact component={RouteTest} />
                    <Route path="/checknumbers" component={CheckNumbersRoutes} />
                    <Route path="/calls" exact component={CallsPage} />
                    <Route path="/sms" exact component={SMSPage} />
                    <Route path="/crm-integrations" component={CrmRoutes} />
                    <Route path="/telephony-history" component={TelephonyHistoryPage} />
                    <Route path="/authorizationshistory" exact component={AuthorizationsHistoryPage} />
                    <Route path="/antirobot" exact component={AntirobotPage} />
                    <Route path="/unbindingnumbers" exact component={UnbindingNumbersPage} />
                    <Route path="/dkt-numbers" component={DktNumbersRoutes} />
                    <Route path="/dt-ratio-statistics" component={DtRatioStatisticsRoutes} />
                    <Route path="/ct3-ratio-statistics" component={Ct3RatioStatisticsRoutes} />
                    <Route path="/sip-lines" exact component={SipLinesPage} />
                    <Route path="/sip-trunks" component={SipTrunksPage} />
                    <Route path="/charges-compare" exact component={ChargesComparePage} />
                    <Route path="/counteragents" exact component={CounterAgentsPage} />
                    <Route path="/routesstatistics" exact component={RoutesStatisticsPage} />
                    <Route path="/contract-template-call" exact component={ContractTemplateCallPage} />
                    <Route path="/force" exact component={ForcePage} />
                    <Route path="/price-lists" component={PriceListsPage} />
                    <Route path="/price-lists-analysis" component={PriceListsAnalysisPage} />
                    <Route path="/price-lists-compare" component={PriceListsComparePage} />
                    <Route path="/zones" component={ZonesPage} />
                    <Route path="/counters" component={env === 'novofon' ? NovofonCountersPage : CountersPage} />
                    <Route path="/number-capacity-counters" component={NumberCapacityCountersPage} />
                    <Route path="/promo-actions" component={PromoActionsPage} />
                    <Route path="/promo-actions-nf" component={PromoActionsNfRoutes} />
                    <Route path="/promo-codes" component={PromoCodesPage} />
                    <Route path="/nets" component={NetsPage} />
                    <Route path="/mtt" component={MTTPage} />
                    <Route path="/locations" component={LocationsPage} />
                    <Route path="/number-type-prefixes" component={PrefixTypesPage} />
                    <Route path="/region-prefixes" component={RegionPrefixesPage} />
                    <Route path="/event-resending" component={EventResending} />
                    <Route path="/feature-flags" component={FeatureFlagsPage} />
                    <Route
                        path="/international-customer-details/:id/:mode"
                        component={InternationalCustomerDetailsPage}
                    />
                    <Route path="/invoices" component={InvoicesPage} />
                    <Route path="/service-addresses" component={ServiceAddressesPage} />
                    <Route path="/partners" component={Partners} />
                    <Route path="/counteragent" exact component={CounteragentPage} />
                    <Route path="/bank-requisites/:counteragent_id" exact component={BankRequisitesPage} />
                    <Route
                        path="/bank-requisites/:counteragent_id/history/:bank_requisite_id"
                        component={BankRequisitesHistoryPage}
                    />
                    <Route
                        path="/bank-requisites/:counteragent_id/edit/:bank_requisite_id"
                        component={BankRequisitesEditPage}
                    />
                    <Route
                        path="/counteragent-requisites/:counteragent_id"
                        exact
                        component={CounteragentRequisitesPage}
                    />
                    <Route
                        path="/counteragent-requisites/:counteragent_id/history/:counteragent_requisite_id"
                        component={CounteragentRequisitesHistoryPage}
                    />
                    <Route
                        path="/counteragent-requisites/:counteragent_id/edit/:counteragent_requisite_id"
                        component={CounteragentRequisitesEditPage}
                    />
                    <Route path="/app-cdr-out-prefixes" component={AppCdrOutPrefixesPage} />
                    <Route path="/waba-accounts" component={WabaAccountsPage} />
                    <Route path="/not-finished-call-sessions" component={NotFinishedCallSessionsRoutes} />
                    <Route path="/app-trials" component={TrialPage} />
                    <Route path="/geo-ips" component={GeoIpsRoutes} />
                    <Route path="/novofon-tariff-plans" component={NovofonTariffPlansPage} />
                    <Route path="/novofon-job-errors" component={NovofonJobErrorsPage} />
                    <Route path="/promised-payments" component={NovofonPromisedPayments} />
                    <Route path="/novofon-number-capacity-costs" component={NovofonNumberCapacityCosts} />
                    <Route path="/novofon-moving-customers" component={NovofonMovingCustomers} />

                    <Redirect to="/" />
                </Switch>
            </Layout>
        ) : null;
    }
}

export default inject('appStore')(observer(AppRoute));
