import { BaseReportStore2 } from '../../../lib/stores/BaseReportStore2';
import httpRpc from '../../../lib/api';
import { yesterdayYmd, todayYmd } from '../../../lib/utils';
import {action, decorate, observable} from 'mobx';


export const defaultFilterParams = {
    // date_from: yesterdayYmd,
    // date_till: todayYmd,
    date_from: undefined,
    date_till: undefined,
    prefix: [],
    numb: undefined,
    service_type: [],
    status: [],
    owner: []
};


export default class DktNumbersLogStore extends BaseReportStore2 {

    constructor(props) {
        super(props);

        this.method = 'get.number_pool_list';
        this.defaultReportParams.filterParams = {...defaultFilterParams};
        this.reportParams.filterParams = {...defaultFilterParams};
        this.defaultTablePagination.withoutTotal = true;
    };

    prepareItems(items) {
        items.map(rec => rec.freePhones = []);
        return items;
    }

    updateNumberPoolErrorMsg = '';

    activatedRec = null;

    setActivatedRec = async (rec) => {
        this.activatedRec = rec;
        if (['reserved', 'freeze'].indexOf(rec.status) !== -1) {
            this.activatedRec = {
                ...this.activatedRec,
                freePhones: await this.getFreeNumberPoolPhones(rec)
            };
        }
    };

    clearActivatedRec = () => {
        this.activatedRec = null;
    };

    updateNumberPoolRecord = async (replacementPhoneId=null) => {
        try {
            this.updateNumberPoolErrorMsg = '';
            return await httpRpc('update.number_pool_record', {
                phone_id: this.activatedRec.number_capacity_id,
                replacement_phone_id: replacementPhoneId
            });
        } catch (e) {
            this.updateNumberPoolErrorMsg = e.message;
        } finally {
            this.clearActivatedRec();
            this.load();
        }
    };

    async getFreeNumberPoolPhones(rec) {
        let params = {
                limit: null,
                offset: 0,
                sort: [],
                prefix: [rec.prefix],
                service_type: [rec.type],
                owner: []
            },
            freePhones = await httpRpc(this.method, {
                ...params,
                status: ['free']
            }),
            res = freePhones.data;

        if (rec.app_id && rec.type === 'dt_private') {
            let freezePhones = await httpRpc(this.method, {
                ...params,
                status: ['freeze']
            });
            if (freezePhones.data.length) {
                res = res.concat(freezePhones.data.filter(item => item.app_id === rec.app_id));
            }
        }
        return res;
    }



}

decorate(DktNumbersLogStore, {
    updateNumberPoolErrorMsg: observable,
    activatedRec: observable.deep,
    setActivatedRec: action,
    clearActivatedRec: action
});
