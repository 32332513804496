import React from 'react';
import {withTranslation} from 'react-i18next';

const getVariant = value => {
    const lastTwoDigits = value % 100;

    if (lastTwoDigits > 10 && lastTwoDigits < 20) {
        return 3;
    }

    switch (value % 10) {
        case 1:
            return 1;
        case 2:
        case 3:
        case 4:
            return 2;
    }

    return 3;
};

export default withTranslation('event-resending')(({count, t}) =>
    t(`events_added_to_queue_${getVariant(count)}`, {count}));
