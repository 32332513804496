import { defaultParams, BaseReportStore } from '../../lib/stores/BaseReportStore';
import { decorate, observable } from 'mobx';

export default class CheckNumbersReportStore extends BaseReportStore {
    method = 'get.act_detalization'

    params = {
        ...defaultParams,
        act_id: null,
        sort: [],
    }

    info = {}

    async load(params) {
        const res = await super.load(params, 'items');
        this.info = res.data.act_info;

    }
}


decorate(CheckNumbersReportStore, {
    info: observable.shallow,
});
