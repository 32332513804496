import {BaseReportStore2} from '../../lib/stores/BaseReportStore2';

const defaultFilterParams = {
    app_id: undefined,
    phone: undefined
};

const defaultTableParams = {
    limit: 100,
    offset: 0,
    sort: [{
        field: 'phone',
        order: 'desc'
    }]
};

export default class RecordsStore extends BaseReportStore2 {
    constructor(props) {
        super(props);

        this.method = 'get.sip_trunks';
        this.defaultReportParams = {
            filterParams: {...defaultFilterParams},
            tableParams: {...defaultTableParams}
        };
        this.reportParams.filterParams = {...defaultFilterParams};
    }
}

