import axios from 'axios';
import { nanoid } from 'nanoid';
import stores from '../../stores';
import { getToken, hasAuthToken } from '../stores/AppStore';

if (process.env.NODE_ENV === 'development') {
    window.axios = axios;
}

const INVALID_TOKEN_CODE = -32001;
const LOGOUT_USER = 'logout.user';

export const baseUrl = process.env.REACT_APP_BASE_URL || '$REACT_APP_BASE_URL';

axios.defaults.baseURL = baseUrl.search(/^http/) !== -1 ? baseUrl : window.location.origin + baseUrl;
axios.defaults.timeout = 1800 * 1000;
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';

let requestsToRefresh = [];
let isRefreshRequesting = false;

const refreshAccessToken = (error, config) => {
    const data = JSON.parse(config.data);
    const { method } = data;

    if (!hasAuthToken() || method === LOGOUT_USER) {
        return Promise.reject(error);
    }

    if (!isRefreshRequesting) {
        isRefreshRequesting = true;

        stores.appStore
            .refresh()
            .then(() => {
                requestsToRefresh.forEach(cb => cb(true));
            })
            .catch(() => {
                requestsToRefresh.forEach(cb => cb(false));
                stores.appStore.logout();
            })
            .finally(() => {
                requestsToRefresh = [];
                isRefreshRequesting = false;
            });
    }

    return new Promise((resolve, reject) => {
        const requestToRefresh = resolveNext => {
            if (resolveNext) {
                data.params.jwt = getToken('jwt');
                config.data = JSON.stringify(data);

                resolve(axios(config));
            }

            reject(error);
        };

        requestsToRefresh.push(requestToRefresh);
    });
};

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response && error.response.status === 401) {
            return refreshAccessToken(error, error.response.config);
        }

        return Promise.reject(error);
    }
);

async function httpRpc(method, params = {}, url = baseUrl) {
    params = {...params};
    const rpcParams = {
        jsonrpc: '2.0',
        id: nanoid(),
        method,
        params,
    };

    rpcParams.params.jwt = getToken('jwt');

    if (method === 'login.user') {
        delete rpcParams.params.jwt;
    } else if (!rpcParams.params.jwt) {
        stores.appStore.logout();

        return Promise.reject(new Error('UNAUTHORIZED'));
    }

    const config = {
        method: 'post',
        data: rpcParams,
        url: `?method=${method}`,
    };

    try {
        const resp = await axios(config);

        if (resp.data.error) {
            console.log('error response', resp);
            stores.appStore.setRequestError(resp.data.error);
            resp.data.error.code === INVALID_TOKEN_CODE && getToken() && stores.appStore.logout();
            return Promise.reject(resp.data.error);
        }
        stores.appStore.setRequestError(null);
        return resp.data.result;
    } catch (e) {
        console.log('server connection error');
        if (!e.response) {
            stores.appStore.showServerError(e);
        }
        return Promise.reject(e);
    }
}

export default httpRpc;
