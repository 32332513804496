import AppStore from '../lib/stores/AppStore';
import ClientsStore from '../interfaces/client/ClientsStore';
import ClientStore from '../interfaces/client/ClientStore';
import ChangesHistoryStore from '../interfaces/changeshistory/ChangesHistoryStore';
import Store from '../interfaces/authorizationshistory/Store';
import AntirobotStore from '../interfaces/antirobot/Store';
import AntirobotAddServiceStore from '../interfaces/antirobot/ServiceStore';
import UnbindingNumbersStore from '../interfaces/unbindingnumbers/UnbindingNumbersStore';
import UnbindingNumbersAddServiceStore from '../interfaces/unbindingnumbers/UnbindingNumbersAddServiceStore';
import RouteStore from '../interfaces/route/RouteStore';
import CheckNumbersStore from '../interfaces/checknumbers/CheckNumbersStore';
import CheckNumbersReportStore from '../interfaces/checknumbers/CheckNumbersReportStore';
import CallsStore from '../interfaces/calls/CallsStore';
import CrmIntegrationsStore from '../interfaces/crm-integration/CrmIntegrationsStore';
import CrmDetailStore from '../interfaces/crm-integration/CrmDetailStore';
import TelephonyHistoryStore from '../interfaces/telephony-history/TelephonyHistoryStore';
import DktNumbersLogStore from '../interfaces/dktnumbers/numberslog/DktNumbersLogStore';
import DktNumbersHistoryStore from '../interfaces/dktnumbers/numbershistory/DktNumbersHistoryStore';
import DktNumbersCallsHistoryStore from '../interfaces/dktnumbers/callshistory/DktNumbersCallsHistoryStore';
import DktNumbersCalculationStore from '../interfaces/dktnumbers/calculation/DktNumbersCalculationStore';
import DtRatioStatisticsStore from '../interfaces/dt-ratio-statistics/Store';
import Ct3RatioStatisticsStore from '../interfaces/ct3-ratio-statistics/Store';
import SipTrunksStore from '../interfaces/sip-trunks/RecordsStore';
import SipTrunkStore from '../interfaces/sip-trunks/RecordStore';
import ChargesCompareStore from '../interfaces/charges-compare/Store';
import RoutesStatisticsStore from '../interfaces/routesstatistics/Store';
import EventsStore from '../interfaces/event-resending/Store';
import FeatureFlagsStore from '../interfaces/feature-flags/Store';
import PartnersStore from '../interfaces/partners/PartnersStore';
import PartnerStore from '../interfaces/partners/PartnerStore';
import TrialStore from '../interfaces/app-trials/Store';
import CheckIpStore from '../interfaces/geo-ips/check-ip/CheckIpStore';
import LocationRangeStore from '../interfaces/geo-ips/location-range/LocationRangeStore';
import PromisedPaymentsStore from '../interfaces/novofon/promised-payments/Store';

class Stores {
    constructor() {
        this.appStore = new AppStore(this);
        this.clientsStore = new ClientsStore(this, 50);
        this.clientStore = new ClientStore(this);
        this.changesHistoryStore = new ChangesHistoryStore(this);
        this.telephonyHistoryStore = new TelephonyHistoryStore(this);
        this.routeStore = new RouteStore(this);
        this.checkNumbersStore = new CheckNumbersStore(this);
        this.checkNumbersReportStore = new CheckNumbersReportStore(this);
        this.callsStore = new CallsStore(this);
        this.crmIntegrationsStore = new CrmIntegrationsStore(this);
        this.crmDetailStore = new CrmDetailStore(this);
        this.authorizationsHistoryStore = new Store(this);
        this.antirobotStore = new AntirobotStore(this);
        this.antirobotAddServiceStore = new AntirobotAddServiceStore(this);
        this.unbindingNumbersStore = new UnbindingNumbersStore(this);
        this.unbindingNumbersAddServiceStore = new UnbindingNumbersAddServiceStore(this);
        this.dktNumbersLogStore = new DktNumbersLogStore(this);
        this.dktNumbersHistoryStore = new DktNumbersHistoryStore(this);
        this.dktNumbersCallsHistoryStore = new DktNumbersCallsHistoryStore(this);
        this.dktNumbersCalculationStore = new DktNumbersCalculationStore(this);
        this.dtRatioStatisticsStore = new DtRatioStatisticsStore(this);
        this.ct3RatioStatisticsStore = new Ct3RatioStatisticsStore(this);
        this.sipTrunksStore = new SipTrunksStore(this);
        this.sipTrunkStore = new SipTrunkStore(this);
        this.chargesCompareStore = new ChargesCompareStore(this);
        this.routesStatisticsStore = new RoutesStatisticsStore(this);
        this.eventsStore = new EventsStore(this);
        this.featureFlagsStore = new FeatureFlagsStore(this);
        this.featureFlagsClientsStore = new ClientsStore(this, 5);
        this.partnersStore = new PartnersStore(this, 50);
        this.partnerStore = new PartnerStore(this);
        this.trialStore = new TrialStore(this);
        this.checkIpStore = new CheckIpStore(this);
        this.locationRangeStore = new LocationRangeStore(this);
        this.promisedPaymentsStore = new PromisedPaymentsStore(this);
    }
}

const stores = new Stores();

export default stores;
