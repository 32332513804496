import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
// const SubMenu = Menu.SubMenu;

class UserMenu extends Component {


    render() {

        const { appStore } = this.props;

        const menu = (
            <Menu>
                <Menu.Item onClick={appStore.logout}>
                    Выйти
                </Menu.Item>
            </Menu>
        );

        return <>
            {
                appStore.user ?
                    <Dropdown overlay={menu}>
                        <a> {appStore.user.login} </a>
                    </Dropdown>
                    :
                    <Link to="/login">
                        Войти
                    </Link>
            }
        </>;
    }
}

export default inject('appStore')(observer(UserMenu));
