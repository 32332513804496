import { decorate, observable, action } from 'mobx';
import httpRpc from '../../lib/api';


export class UnbindingNumbersAddServiceStore {

    loading = false;

    setServiceMethod = 'set.dt_unbinding_numbers_is_ack';
    loadUnbindingNumbersAcsMethod = 'get.dt_unbinding_numbers_acs';

    operationRec = {
        [this.setServiceMethod]: { status: '' },
        [this.loadUnbindingNumbersAcsMethod]: { status: '' }
    };

    setOperationRecStatus(operationName, status) {
        this.operationRec[operationName].status = status;
    }

    unbindingNumbersAcs = {
        sites: [],
        ac_by_site: {}
    };

    addServiceStruct = {
        app_id: null,
        site_id: null,
        ac_id: null,
        action: 'activate'
    };

    setLoadTimeout(app_id) {
        if (app_id.match(/^[0-9]{1,9}$/)) {
            window.clearTimeout(this.loadUnbindingNumbersAcsTimeout);
            this.loadUnbindingNumbersAcsTimeout = window.setTimeout(() => this.loadUnbindingNumbersAcs(app_id), 500);
        }
    }

    async loadUnbindingNumbersAcs(app_id) {
        console.log('loadUnbindingNumbersAcs  ', app_id);
        const res = await this.req(
            this.loadUnbindingNumbersAcsMethod,
            {app_id: app_id},
            'load Unbinding Numbers Acs error'
        );
        console.log(res.data);
        this.unbindingNumbersAcs = res.data;
    }

    async addService(params, formActions) {
        const res = await this.req(
            this.setServiceMethod,
            Object.assign({}, this.addServiceStruct, params),
            'add service error'
        );
        formActions.setSubmitting(false);
        return res;
    }

    async removeService(params) {
        const rec = {};
        Object.keys(this.addServiceStruct).map(k => rec[k] = params[k]);
        return this.req(
            this.setServiceMethod,
            Object.assign({}, rec, { action: 'deactivate' }),
            'remove service error'
        );
    }

    async req(method, params, errorMsg) {
        this.loading = true;
        try {
            const res = await httpRpc(method, params);
            this.operationRec[method] = {
                status: 'success',
                res: res
            };
            return res;
        } catch (e) {
            this.operationRec[method] = {
                status: 'error',
                errorMsg: e.message
            };
            console.log(errorMsg || 'send method error', e);
        } finally {
            this.loading = false;
        }
    }

}

decorate(UnbindingNumbersAddServiceStore, {
    loading: observable,
    operationRec: observable.deep,
    unbindingNumbersAcs: observable.deep,
    setOperationRecStatus: action
});

export default UnbindingNumbersAddServiceStore;
