import moment from 'moment';
import {dateFormat} from '../../lib/utils';
import {BaseReportStore2} from '../../lib/stores/BaseReportStore2';

const defaultFilterParams = {
    date_from: moment().startOf('month').format(dateFormat),
    date_till: moment().format(dateFormat),
    app_id: undefined,
    login: undefined,
    ip: undefined,
    is_successful: undefined,
    source: undefined,
};

const defaultTableParams = {
    limit: 100,
    offset: 0,
    sort: [{
        field: 'login_time',
        order: 'desc'
    }]
};

export default class Store extends BaseReportStore2 {
    constructor(props) {
        super(props);

        this.method = 'get.auth_history';
        this.defaultReportParams = {
            filterParams: {...defaultFilterParams},
            tableParams: {...defaultTableParams}
        };
        this.reportParams.filterParams = {...defaultFilterParams};
        this.reportParams.tableParams = {...defaultTableParams};
    }
}
