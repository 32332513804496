import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { Button, Row, Form, Input } from 'antd';
const FormItem = Form.Item;

class LoginRoute extends Component {

    handleOk = () => {
        const { form } = this.props;
        const { validateFieldsAndScroll } = form;
        validateFieldsAndScroll((errors, values) => {
            if (errors) {
                return;
            }
            this.props.appStore.login(values);
        });
    }

    render() {

        const { form } = this.props;
        const { getFieldDecorator } = form;
        const error = this.props.appStore.loginError;

        return this.props.appStore.isAuthenticated ? <Redirect to='/' /> : (
            <Fragment>
                <div style={{ padding: 50, width: 500 }}>
                    <Form>
                        <FormItem hasFeedback>
                            {getFieldDecorator('login', {
                                rules: [
                                    {
                                        required: true,
                                    },
                                ],
                            })(
                                <Input
                                    onPressEnter={this.handleOk}
                                    placeholder={'Username'}
                                />
                            )}
                        </FormItem>
                        <FormItem hasFeedback>
                            {getFieldDecorator('password', {
                                rules: [
                                    {
                                        required: true,
                                    },
                                ],
                            })(
                                <Input
                                    type="password"
                                    onPressEnter={this.handleOk}
                                    placeholder={'Password'}
                                />
                            )}
                        </FormItem>
                        <Row>
                            <Button
                                type="primary"
                                onClick={this.handleOk}
                                loading={false}
                            >
                                Sign in
              </Button>

                        </Row>
                    </Form>
                    {error && error.message && <div className="has-error"><span className="ant-form-explain">{error.message}</span></div>}
                </div>
            </Fragment>
        );
    }
}

export default inject('appStore')((Form.create()(observer(LoginRoute))));
