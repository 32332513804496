import { BaseReportStore2 } from '../../lib/stores/BaseReportStore2';
import moment from 'moment';
import { dateTimeFormat } from '../../lib/utils';


export const defaultFilterParams = {
    app_id: undefined,
    date_from: moment().subtract(1, 'days').startOf('date').format(dateTimeFormat),
    date_till: moment().endOf('date').format(dateTimeFormat),
    phone: undefined,
    events: []
};

export default class TelephonyHistoryStore extends BaseReportStore2 {

    constructor(props) {
        super(props);

        this.method = 'get.telephony_history';
        this.defaultReportParams.filterParams = {...defaultFilterParams};
        this.reportParams.filterParams = {...defaultFilterParams};
    }

    onFiltersFormSubmit(values, action) {
        !values.app_id && delete values.app_id;
        super.onFiltersFormSubmit(values, action);
    }

}
