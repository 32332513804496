import React, { Component } from 'react';
import { AuthProtectedRoute } from './lib/components/Auth';
import LoginRoute from './routes/LoginRoute';
import AppRoute from './routes/AppRoute';
import { Switch, Route } from 'react-router-dom';
import './App.less';

class App extends Component {

    render() {
        return (
            <Switch>
                <Route path="/login" component={LoginRoute} />
                <AuthProtectedRoute path="/" component={AppRoute} />
            </Switch>
        );
    }
}

export default App;
