import { BaseReportStore2 } from '../../../lib/stores/BaseReportStore2';
import httpRpc from '../../../lib/api';

export const defaultFilterParams = {
    start_ip: undefined,
    end_ip: undefined,
    location: 'ru'
};

export default class LocationRangeStore extends BaseReportStore2 {

    constructor(props) {
        super(props);

        this.method = 'get.location_range';
        this.defaultReportParams.filterParams = {...defaultFilterParams};
        this.reportParams.filterParams = {...defaultFilterParams};
        this.defaultTablePagination.withoutTotal = true;
    };

    async update(rec, geoname_id) {
        await httpRpc('update.geoip_location', {
            geoname_id: geoname_id,
            start_ip_inet: rec.start_ip_inet,
            end_ip_inet: rec.end_ip_inet
        });
    }
}
