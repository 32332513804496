import { decorate, observable, action } from 'mobx';
import httpRpc from '../../lib/api';


export class ServiceStore {

    loading = false;

    removeServiceMethod = 'set.stop_antirobot_experiment';

    operationRec = {
        [this.removeServiceMethod]: { status: '' }
    };

    setOperationRecStatus(operationName, status) {
        this.operationRec[operationName].status = status;
    }

    serviceStruct = {
        app_id: null,
        site_id: null
    };

    async removeService(params) {
        const rec = {};
        Object.keys(this.serviceStruct).map(k => rec[k] = params[k]);
        return this.req(
            this.removeServiceMethod,
             rec,
            'remove service error'
        );
    }

    async req(method, params, errorMsg) {
        this.loading = true;
        try {
            const res = await httpRpc(method, params);
            this.operationRec[method] = {
                status: 'success',
                res: res
            };
            return res;
        } catch (e) {
            this.operationRec[method] = {
                status: 'error',
                errorMsg: e.message
            };
            console.log(errorMsg || 'send method error', e);
        } finally {
            this.loading = false;
        }
    }

}

decorate(ServiceStore, {
    loading: observable,
    operationRec: observable.deep,
    setOperationRecStatus: action
});

export default ServiceStore;
