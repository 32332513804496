import { BaseReportStore } from '../../lib/stores/BaseReportStore';
import { decorate, observable } from 'mobx';

class RouteStore extends BaseReportStore {

    method = 'get.route_test_result'

    params = {

    }

    routeInfo = {}

    async load(params) {
        try {
            const res = await super.load(params, 'routes');
            const { routes, ...info } = res.data;
            this.routeInfo = info;

        } catch (e) {
            console.log('RouteStore load error');
        }
    }

}

decorate(RouteStore, {
    routeInfo: observable.shallow,
});

export default RouteStore;
