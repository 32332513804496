import { BaseReportStore2 } from '../../../lib/stores/BaseReportStore2';
import {decorate, observable} from 'mobx';
import httpRpc from '../../../lib/api';


export const defaultFilterParams = {

    stringifyInputData: '[{\"prefix\":null,\"traffic\":null}]'

};

class DktNumbersCalculationStore extends BaseReportStore2 {

    constructor(props) {
        super(props);

        this.method = 'get.simple_dt_numbers_calculation';
        this.defaultReportParams.filterParams = {...defaultFilterParams};
        this.reportParams.filterParams = {...defaultFilterParams};
        this.locationData = [];
    }

    async loadLocations() {
        this.locationData = (await httpRpc('get.locations')).data;
    }

}

decorate(DktNumbersCalculationStore, {
    locationData: observable
});

export default DktNumbersCalculationStore;
