import {decorate, observable} from 'mobx';
import {isEqual} from 'lodash';
import {BaseReportStore2} from './BaseReportStore2';


class BaseReportStore2WithLocalPagingAndSorting extends BaseReportStore2 {
    hasTotalRow = true;

    prepareItems(items, metadata) {
        if (!metadata) {
            metadata = this.metadata;
        }
        if (items) {
            this.data = items;
        } else {
            items = this.data;
        }
        if (this.reportParams.tableParams.sort.length) {
            let {field, order} = this.reportParams.tableParams.sort[0];
            this.data = this.data.sort((r1, r2) => {
                if (r1[field] === r2[field]) {
                    return 0;
                } else if (r1[field] === null || r1[field] < r2[field]) {
                    return order === 'asc' ? -1 : 1;
                } else {
                    return order === 'asc' ? 1 : -1;
                }
            });
        }
        const {limit, offset} = this.reportParams.tableParams;
        return []
            .concat(this.hasTotalRow ? [metadata.total_row] : [])
            .concat(items.slice(parseInt(offset), parseInt(offset) + parseInt(limit)));
    }

    async load() {
        if (this.isLoadByTableParams) {
            this.isSubmitting = false;
            this.isLoadByTableParams = false;
            this.items = this.prepareItems();
            this.isLoaded = true;
            this.tablePagination = {
                ...this.tablePagination,
                total: this.data.length
            };
        } else {
            await super.load();
        }
    }

    checkReportParamsElse() {
        const {filterParams, tableParams} = this.reportParams,
            nextReportParams = this.getNextReportParams();

        if (this.isLoaded && isEqual(filterParams, nextReportParams.filterParams) && !isEqual(tableParams, nextReportParams.tableParams)) {
            this.isLoadByTableParams = true;
        } else {
            this.isLoadByTableParams = false;
        }

        super.checkReportParamsElse();
    };
}


decorate(BaseReportStore2WithLocalPagingAndSorting, {
    items: observable.shallow,
    metadata: observable.shallow,
    tablePagination: observable.shallow
});

export default BaseReportStore2WithLocalPagingAndSorting;
