import {decorate, observable} from 'mobx';
import BaseStore from './BaseStore';
import {baseUrl} from '../api/index';
import history from '../../history';
import queryString from 'query-string';
import {isEqual} from 'lodash';

export const defaultTableParams = {
    limit: 50,
    offset: 0,
    sort: null,
    // filter: null
};

// скорее всего потом надо быдет определить это в this.defaultTablePagination
export const defaultTablePagination = {
    showSizeChanger: true,
    pageSizeOptions: ['25', '50', '100', '200', '400'],
    current: (defaultTableParams.offset / defaultTableParams.limit) + 1,
    pageSize: defaultTableParams.limit,
    total: 0
};

// стор для отчетов, хранит items и metadata,
// умеет сортировку, пагинацию, фильтрацию
export class BaseReportStore2 extends BaseStore {
    sortOrder = ['asc', 'desc'];
    isMultipleSort = false;
    isOnTableParamsChangeIfNotLoadedBeforeLoad = false;

    constructor(props) {
        super(props);

        this.defaultReportParams = this.defaultReportParams || {
            tableParams: {...defaultTableParams},
            filterParams: null
        };

        this.defaultTablePagination = {...defaultTablePagination};

        this.initParams();
    };

    initParams() {
        this.items = [];
        this.reportParams = {...this.defaultReportParams};
        this.metadata = {};
        this.tablePagination = {...this.defaultTablePagination};
        this.isLoaded = false;

        this.autoload = false;
    };

    prepareItems(items) {
        return items;
    }

    getLoadParams() {
        return {...this.reportParams.tableParams, ...this.reportParams.filterParams};
    }

    async load(dataKey = null) {
        this.isSubmitting = false;
        try {
            const res = await super.load(this.getLoadParams());
            this.items = this.prepareItems(dataKey ? (res.data[dataKey] || []) : res.data, res.metadata);
            this.metadata = res.metadata;
            this.isLoaded = true;

            this.tablePagination = {
                ...this.tablePagination,
                total: this.metadata ? this.metadata.total_items : this.items.length
            };
            // return res;
        } catch (e) {
            this.items = [];
            console.log('BaseReportStore load error');
            throw e;
        }
    }

    getExportCsvParams() {
        return {
            ...this.reportParams.tableParams, ...this.reportParams.filterParams,
            export: 1,
            limit: null,
            offset: null
        };
    }

    exportCsv = async () => {
        const params = this.getExportCsvParams();
        try {
            const res = await super.load(params);
            window.open(baseUrl + res.url.slice(1), '_blank');
        } catch (e) {
            console.log('export csv error');
        }
    };

    // setFilter(name, value) {  // ??? в каких случаях используется setFilter ?
    //     if (!this.reportParams.tableParams.filter) {
    //         this.reportParams.tableParams.filter = [];
    //     }
    //     let filter = this.reportParams.tableParams.filter;
    //     const filterIndex = filter.findIndex(f => f.field === name);
    //
    //     if (value) {
    //         const filterValue = { field: name, value: value, operator: 'eq' };
    //         filterIndex === -1 ? filter.push(filterValue) : filter[filterIndex] = filterValue;
    //     } else {
    //         if (filterIndex !== -1) {
    //             filter.splice(filterIndex, 1);
    //         }
    //     }
    // };

    getNextReportParams(search = this.getSearch()) {
        const paramsFromUrl = queryString.parse(search, {arrayFormat: 'index'});
        if (search && paramsFromUrl.sort && paramsFromUrl.sort.length) {
            paramsFromUrl.sort = paramsFromUrl.sort.map(i => i ? JSON.parse(i) : i);
        } else if (search && !paramsFromUrl.sort) {
            paramsFromUrl.sort = [];
        }
        const getNextParams = (params, defaultParams) => Object.keys(params).reduce((res, key) => ({
            ...res,
            [key]: key in paramsFromUrl ? paramsFromUrl[key] : defaultParams[key]
        }), {});
        return {
            filterParams: search ? getNextParams(this.reportParams.filterParams || {}, this.defaultReportParams.filterParams) : this.defaultReportParams.filterParams,
            tableParams: search ? getNextParams(this.reportParams.tableParams, this.defaultReportParams.tableParams) : this.defaultReportParams.tableParams
        };
    };

    onFiltersFormSubmit(values, action) {
        this.passCheckReportParamsElse = true;
        this.passCheckReportParamsElse = false;
        const search = this.getNextSearch(values, {...this.reportParams.tableParams, offset: 0});
        this.isSubmitting = true;
        this.setSearch(search);
    };

    setTableDefaults() {
        this.reportParams.tableParams = {...this.defaultReportParams.tableParams};
        this.tablePagination = {...this.defaultTablePagination};
    };

    getNextSearch(filterParams, tableParams) {
        const filterParamsSearch = queryString.stringify(filterParams, {arrayFormat: 'index'}),
            tableParamsSearch = queryString.stringify({
                ...tableParams,
                sort: tableParams.sort ? tableParams.sort.map(i => JSON.stringify(i)) : null
            }, {arrayFormat: 'index'}),
            separator = filterParamsSearch && tableParamsSearch ? '&' : '';
        return filterParamsSearch + separator + tableParamsSearch;
    }

    onTableParamsChange = (pagination, filter, sort, ...rest) => {
        if (!this.isOnTableParamsChangeIfNotLoadedBeforeLoad && !this.isLoaded) {
            return;
        }
        let currentTableSortParam = this.reportParams.tableParams.sort || [],
            nextTableParams = {
                limit: pagination.pageSize,
                offset: (pagination.current - 1) * pagination.pageSize,
            },
            tableSortParam = [],
            isNewSortWas = false;

        if (!sort) {
            tableSortParam = currentTableSortParam;
        } else {
            this.isMultipleSort && currentTableSortParam.forEach(s => {
                if (s.field !== sort.field) {
                    tableSortParam.push(s);
                } else {
                    isNewSortWas = true;
                    let nextSortIndex = this.sortOrder.indexOf(s.order) + 1;
                    if (this.sortOrder.length !== nextSortIndex) {
                        tableSortParam.push({
                            field: sort.field,
                            order: this.sortOrder[nextSortIndex]
                        });
                    }
                }
            });
            if (this.isMultipleSort) {
                !isNewSortWas && tableSortParam.push({
                    field: sort.field,
                    order: this.sortOrder[0]
                });
            } else {
                let nextSortIndex = currentTableSortParam.length && currentTableSortParam[0].field === sort.field ?
                    this.sortOrder.indexOf(currentTableSortParam[0].order) + 1 : 0;
                if (this.sortOrder.length !== nextSortIndex) {
                    tableSortParam.push({
                        field: sort.field,
                        order: this.sortOrder[nextSortIndex]
                    });
                }
            }
        }

        nextTableParams.sort = tableSortParam;

        if (!isEqual(nextTableParams.sort, this.reportParams.tableParams.sort || []) && nextTableParams.offset !== 0) {
            nextTableParams.offset = 0;
        }

        const search = this.getNextSearch(this.reportParams.filterParams, nextTableParams);

        this.isSubmitting = true;
        this.setSearch(search);
    };

    setTablePaginationFromUrlParams({offset, limit}) {
        this.tablePagination = {...this.defaultTablePagination, pageSize: +limit, current: +offset / +limit + 1};
    };

    checkReportParamsElse() {
        if (this.passCheckReportParamsElse) {
            return;
        }

        const {filterParams, tableParams} = this.reportParams,
            nextReportParams = this.getNextReportParams();

        if (
            this.isSubmitting
            || (!this.getSearch() && this.autoload)
            || (!isEqual(filterParams, nextReportParams.filterParams)
            || !isEqual(tableParams, nextReportParams.tableParams))
        ) {
            const {limit, offset} = nextReportParams.tableParams;
            limit && offset && this.setTablePaginationFromUrlParams({limit, offset});
            this.reportParams = {...nextReportParams};
            const searchFromParams = this.getNextSearch(this.reportParams.filterParams, this.reportParams.tableParams);
            this.setSearch(searchFromParams);
            this.load();
        }
    }

    checkReportParams() {
        const {filterParams, tableParams} = this.reportParams,
            nextReportParams = this.getNextReportParams();

        if (!this.getSearch() && !this.autoload && !this.isSubmitting) {
            if (!isEqual(filterParams, nextReportParams.filterParams) || !isEqual(tableParams, nextReportParams.tableParams)) {
                this.initParams();
            }
        } else {
            this.checkReportParamsElse();
        }
    };

    getSearch() {
        return history.location.search;
    }

    setSearch(search) {
        const {hash, pathname, state} = history.location;
        history.replace({hash, pathname, state, search: search});
    }
}

decorate(BaseReportStore2, {
    items: observable.shallow,
    metadata: observable.shallow,
    tablePagination: observable.shallow
});

export default BaseReportStore2;
