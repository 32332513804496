import { defaultParams, BaseReportStore } from '../../lib/stores/BaseReportStore';
import { decorate, observable } from 'mobx';

export default class CrmDetailStore extends BaseReportStore {
    method = 'get.crm_detail'

    params = {
        ...defaultParams,
        app_id: null,
        sort: [{ field: 'is_main_user', order: 'desc' }]
    }

    info = {

    }

    async load(params) {
        const res = await super.load(params);
        this.info = res.info[0];
        return res;
    }
}

decorate(CrmDetailStore, {
    info: observable.shallow,
});
