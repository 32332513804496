import {BaseReportStore2} from '../../lib/stores/BaseReportStore2';
import moment from 'moment';
import {dateTimeFormat} from '../../lib/utils';

const defaultTableParams = {
    limit: 50,
    offset: 0,
    sort: [{
        field: 'date_time',
        order: 'desc'
    }]
};

export const defaultFilterParams = {
    app_id: undefined,
    date_from: moment().subtract(1, 'days').startOf('date').format(dateTimeFormat),
    date_till: moment().endOf('date').format(dateTimeFormat),
    is_use_app_timezone: false,
    record_id: undefined,
    table_names: undefined,
    user_name: undefined,
    old_values: undefined,
    new_values: undefined
};

class ChangesHistoryStore extends BaseReportStore2 {
    constructor(props) {
        super(props);

        this.method = 'get.revision_history_report';
        this.defaultReportParams = {
            filterParams: {...defaultFilterParams},
            tableParams: {...defaultTableParams}
        };
        this.reportParams.filterParams = {...defaultFilterParams};
    }
}

export default ChangesHistoryStore;

