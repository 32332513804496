import React from 'react';
import moment from 'moment';
import {decorate, action, observable, computed} from 'mobx';
import {BaseReportStore2} from '../../lib/stores/BaseReportStore2';
import httpRpc from '../../lib/api';
import {parseArrayInCurlyBraces} from '../../lib/utils';
import history from '../../history';
import {notification} from 'antd';

const defaultTableParams = {
    limit: 10,
    offset: 0,
    sort: [{
        field: 'expire_date',
        order: 'desc'
    }]
};

const defaultFilterParams = {
    search_string: '',
    target: 'all',
    namespaces: undefined
};

class Store extends BaseReportStore2 {
    constructor(props) {
        super(props);

        this.loading = false;
        this.availableNamespaces = [];

        this.closeModalWindow = () => {
            this.modal = {
                title: '',
                okText: '',
                content: '',
                params: {},
                opened: false,
                onOk: () => null
            };
        };

        this.closeModalWindow();
        this.app_ids = [];
        this.method = 'get.feature_flags';

        this.defaultReportParams = {
            filterParams: {...defaultFilterParams},
            tableParams: {...defaultTableParams}
        };

        this.reportParams.filterParams = {...defaultFilterParams};

        const pageSizeOptions = ['5', '10', ...this.defaultTablePagination.pageSizeOptions];
        this.defaultTablePagination.pageSizeOptions = pageSizeOptions;
        this.defaultTablePagination.pageSize = this.tablePagination.pageSize = defaultTableParams.limit;
    }

    openFeatureFlagsPage() {
        history.push('/feature-flags' + (this.search ? '?' + this.search : ''));
    }

    openFeatureFlagPage(url) {
        const {filterParams, tableParams} = this.reportParams;
        this.id = null;
        this.search = this.getNextSearch(filterParams, tableParams);
        history.push(url);
    }

    getItem(desiredId) {
        const item = this.items.find(({id}) => id == desiredId) || {},
            {app_ids, is_global, name} = item;

        return {
            ...item,
            isAppliable: (app_ids || []).length || is_global,
            params: {
                '{name}': `"${name}"`
            }
        };
    }

    enumerate(items, t) {
        if (!items) {
            return '';
        }

        const {length} = items;

        if (!length) {
            return '';
        }

        if (length == 1) {
            return items[0];
        }

        return `${items.slice(0, length - 1).join(', ')} ${t('and')} ${items[length - 1]}`;
    }

    askToConfirmFlagUpdating(requestParams, t) {
        const {is_enabled, id} = requestParams,
            {name, app_ids, namespaces, isAppliable, params} = this.getItem(id);

        this.modal = {
            ...(is_enabled ? {
                title: 'enable_title',
                okText: 'enable',
                content: isAppliable ? 'enable_warning' : 'no_app_id_enable_warning',
                params: {
                    ...params,
                    '{namespaces}': this.enumerate(parseArrayInCurlyBraces(namespaces), t),
                    '{app_ids}': (app_ids || []).length ? this.enumerate(app_ids, t) : t('all_clients')
                }
            } : {
                title: 'disable_title',
                okText: 'disable',
                content: isAppliable ? 'disable_warning' : 'no_app_id_disable_warning',
                params
            }),
            opened: true,
            onOk: () => this.saveFeatureFlag(requestParams, () =>
                (this.items = this.items.map(item => item.id == id ? {...item, is_enabled} : item)), t)
        };
    }

    askToConfirmFlagDeleting(id) {
        const {name, params, isAppliable} = this.getItem(id);

        this.modal = {
            title: 'delete_title',
            okText: 'delete',
            content: isAppliable ? 'delete_warning' : 'no_app_id_delete_warning',
            opened: true,
            onOk: () => this.deleteFeatureFlag(id),
            params
        };
    }

    async deleteFeatureFlag(id) {
        this.closeModalWindow();
        this.loading = true;

        try {
            await httpRpc('delete.feature_flag', {id});
            this.loading = false;
            this.load();
        } catch (e) {
            this.loading = false;
        }
    }

    async getFeatureFlagNamespaces() {
        this.loading = true;

        try {
            this.availableNamespaces = parseArrayInCurlyBraces(await httpRpc('get.feature_flag_namespaces'));
            this.loading = false;
        } catch (e) {
            this.loading = false;
        }
    }

    async getFeatureFlag(id) {
        if (!id) {
            this.clearForm();
            return;
        }

        this.loading = true;

        try {
            const flag = await httpRpc('get.feature_flag', {id}),
                identity = value => value;

            [
                ['app_ids', values => (values || []).sort()],
                ['name', identity],
                ['mnemonic', identity],
                ['namespaces', parseArrayInCurlyBraces],
                ['is_enabled', identity],
                ['expire_date', identity]
            ].forEach(([key, process]) => this[key] = process(flag[key]));

            this.target = flag.is_global ? 'global' : 'app_ids';
            this.id = id;
            this.loading = false;
        } catch (e) {
            this.loading = false;
        }
    }

    async doSaveFeatureFlag(values, handleSuccess, t) {
        this.closeModalWindow();
        this.loading = true;

        try {
            const {target, app_ids, ...params} = values;
            this.id && (params.id = this.id);

            await httpRpc(params.id ? 'update.feature_flag' : 'create.feature_flag', {
                ...params,
                ...(target ? {
                    is_global: target == 'global',
                    app_ids: target == 'app_ids' ? app_ids : null
                } : { app_ids })
            });

            this.loading = false;
            handleSuccess();
        } catch (e) {
            this.loading = false;

            notification.error({
                duration: 3,
                message: t('error notification title'),
                description: e.message
            });
        }
    }

    async saveFeatureFlag(values, handleSuccess, t) {
        const save = () => this.doSaveFeatureFlag(values, handleSuccess, t);

        values.target == 'global' && this.target == 'app_ids' && this.app_ids.length ? (this.modal = {
            title: 'type_change_title',
            okText: 'save',
            content: 'type_change_warning',
            opened: true,
            onOk: save,
            params: {}
        }) : save();
    }

    clearForm() {
        this.id = 0;
        this.name = '';
        this.mnemonic = '';
        this.expire_date = undefined;
        this.namespaces = undefined;
        this.is_enabled = false;
        this.app_ids = [];
        this.target = 'global';
    }

    initParams() {
        super.initParams();
        this.clearForm();
        this.search = '';
        this.loading = false;
        this.availableNamespaces = [];
        this.closeModalWindow && this.closeModalWindow();
    }

    getNextReportParams(...args) {
        const params = super.getNextReportParams(...args) || {};
        params.tableParams = params.tableParams || {};

        ['limit', 'offset'].forEach(name => (params.tableParams[name] =
            `${parseInt(params.tableParams[name], 0) || 0}`));

        return params;
    }

    processParams(params) {
        params = {...params};
        const {sort} = params;
        delete(params.sort);

        switch (params.target) {
            case 'global':
                params.is_global = true;
                break;
            case 'app_ids':
                params.is_global = false;
                break;
            case 'all':
                delete(params.is_global);
                break;
        }

        ['limit', 'offset'].forEach(name => (params[name] = parseInt(params[name], 0) || 0));

        if (sort && sort[0]) {
            params.sort_by = sort[0].field;
            params.sort_asc = sort[0].order == 'asc';
        }

        return params;
    }
}

decorate(Store, {
    id: observable,
    name: observable,
    mnemonic: observable,
    namespaces: observable,
    expire_date: observable,
    is_enabled: observable,
    app_ids: observable,
    target: observable,
    availableNamespaces: observable,
    modal: observable,
    search: observable
});

export default Store;
