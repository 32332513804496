import BaseReportStore2WithLocalPagingAndSorting from '../../lib/stores/BaseReportStore2WithLocalPagingAndSorting';

const defaultFilterParams = {
    app_ids: undefined,
    customer_ids: undefined,
    tp_id: undefined,
    option_ids: undefined,
    components: undefined,
    app_state: undefined,
    new_tpv_id: undefined,
    new_optionv_ids: undefined,
    group_by: [],
    is_ignore_app_op: false,
    is_new_ops_include_app_op: false
};

const defaultTableParams = {
    limit: 100,
    offset: 0,
    sort: null
};


export default class ChargesCompareStore extends BaseReportStore2WithLocalPagingAndSorting {
    constructor(props) {
        super(props);

        this.method = 'get.charges_compare';
        this.defaultReportParams = {
             filterParams: {...defaultFilterParams},
             tableParams: {...defaultTableParams}
        };
        this.reportParams.filterParams = {...defaultFilterParams};
        this.reportParams.tableParams = {...defaultTableParams};
    }
};
