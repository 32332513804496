import BaseReportStore, { defaultParams } from '../../lib/stores/BaseReportStore';

export default class CheckNumbersStore extends BaseReportStore {
    method = 'get.acts'

    params = {
        ...defaultParams,
        sort: [{ field: 'check_date', order: 'desc' }],
    }


    load(params) {
        return super.load(params, 'items');
    }
}
