import React from 'react';
import moment from 'moment';
import {decorate, action, observable, computed} from 'mobx';
import {BaseReportStore2} from '../../lib/stores/BaseReportStore2';
import httpRpc from '../../lib/api';
import DateRange from './DateRange';
import {notification} from 'antd';
import Message from './Message';

const defaultTableParams = {
    limit: 25,
    offset: 0,
    sort: [{
        field: 'event_time',
        order: 'desc'
    }]
};

const defaultFilterParams = {
    id: undefined,
    app_id: undefined,
    partner: undefined,
    numa: undefined,
    date_from: undefined,
    date_till: undefined,
    is_show_not_sent: 'false',
    is_show_in_process: 'true',
    is_show_delivered: true,
    is_show_undelivered: true
};

class Store extends BaseReportStore2 {
    constructor(props) {
        super(props);

        this.ids = [];

        this.method = 'get.amocrm_events';
        this.defaultReportParams = {
            filterParams: {...defaultFilterParams},
            tableParams: {...defaultTableParams}
        };
        this.reportParams.filterParams = {...defaultFilterParams};
        this.defaultTablePagination.pageSize = this.tablePagination.pageSize = defaultTableParams.limit;
        this.defaultTablePagination.withoutTotal = true;
    }

    processParams(params) {
        params = {...params};
        const {sort} = params;
        delete(params.sort);

        if (sort && sort[0]) {
            params.sort_order = sort[0].order;
        }

        return params;
    }
    
    get isEmpty() {
        return !this.ids.length;
    }
    
    selectAllUndelivered() {
        this.ids = this.items.filter(
            ({state}) => !['success', 'in_process', 'planned'].includes(state)
        ).map(({id}) => id);
    }

    getNextReportParams() {
        const params = super.getNextReportParams();
        DateRange.fixRequestParams(params.filterParams);
        return params;
    }

    initParams() {
        super.initParams();
        this.ids = [];
        this.loading = false;
    }

    async resend({app_id, partner}) {
        this.loading = true;

        try {
            await httpRpc('send.amocrm_events', {
                app_id,
                partner,
                ids: this.ids
            });

            notification.open({
                message: <Message count={this.ids.length} />
            });

            this.ids = [];
            this.load();
        } catch (e) {
            this.loading = false;
        }
    }

    handleRowSelectionChange(keys) {
        this.ids = [...keys];
    }
}

decorate(Store, {
    ids: observable,
    resend: action,
    initParams: action,
    isEmpty: computed,
    handleRowSelectionChange: action,
    selectAllUndelivered: action
});

export default Store;
