import React from 'react';
import { decorate, observable, action, computed } from 'mobx';
import httpRpc from '../api';
import { notification } from 'antd';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { dateTimeFormat } from '../utils';
import env from '../../env';
const today = moment();

export const reportDefaults = {
    dates: {
        from: today.clone().subtract(1, 'days').startOf('day').format(dateTimeFormat),
        to: today.startOf('day').format(dateTimeFormat),
    },
};

const requiredDirectoryList = [];

export default class AppStore {
    isAuthenticated = hasAuthToken();
    user = null;
    requestError = null;
    loginError = null;

    //for future
    domainSettings = {};
    projectSettings = {};
    reportDefaults = reportDefaults;
    pagesParams = observable.map({});

    directory = {};

    constructor(stores) {
        this.stores = stores;
    }

    init() {
        this.getUser();
        this.getDirectories(requiredDirectoryList);
    }

    async getUser() {
        try {
            const res = await httpRpc('get.user');
            this.user = res.data;
        } catch (e) {
            this.user = null;
        }
    }

    async login({ login, password }) {
        try {
            this.loginError = null;

            const res = await httpRpc('login.user', { login, password });

            setToken(res.data);
            this.isAuthenticated = hasAuthToken();
        } catch (e) {
            console.log(e);
            this.loginError = e;
            removeToken();

            return Promise.reject(e);
        }
    }

    async refresh() {
        try {
            const auth = getToken();
            const res = await httpRpc('refresh.jwt', auth);

            setToken(res.data);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    async logout() {
        try {
            await httpRpc('logout.user');
        } catch (e) {
            return Promise.reject(e);
        } finally {
            removeToken();
            this.isAuthenticated = false;
            this.user = null;
        }
    }

    setRequestError = error => {
        this.requestError = error;
    };

    showServerError = e => {
        this.serverError = e;
        notification.error({
            message: <Trans>Сервер недоступен</Trans>,
            description: JSON.stringify(e.response),
        });
    };

    getDirectories = async names => {
        const cached = [],
            toLoad = [];
        names.forEach(n => {
            this.directory[n] ? cached.push(n) : toLoad.push(n);
        });
        if (!toLoad.length) {
            return cached.map(n => this.directory[n]);
        }

        try {
            const resp = await httpRpc('get.directories', { keys: toLoad });
            toLoad.forEach(name => {
                // this.directory[name] = resp[name];
                this.directory = { ...this.directory, [name]: resp[name] };
            });
            return names.map(n => this.directory[n]);
        } catch (e) {
            console.log('%c Directories Load Error: ' + e.message, 'color: red;');
        }
    };

    getDirectory = dirName => {
        return this.directory[dirName] ? this.directory[dirName].slice() : [];
    };

    get isReady() {
        return this.user && this.user.permissions && requiredDirectoryList.every(dirName => this.directory[dirName]);
    }

    checkInterfacePermission(permissions, interfaceEnv) {
        if (interfaceEnv && interfaceEnv !== env) {
            return false;
        }

        if (!permissions) {
            return true;
        }

        for (let i = 0; i < permissions.length; i++) {
            if (this.checkPermission(permissions[i])) {
                return true;
            }
        }
        return false;
    }

    checkPermission(mnemonic, right) {
        return (this.user.permissions[mnemonic] || '').indexOf(right || 'r') !== -1;
    }
}

const AUTH_KEY = 'auth';

export function getToken(key) {
    const token = localStorage.getItem(AUTH_KEY);

    if (!token) return;

    const parsedToken = JSON.parse(token);

    if (key) return parsedToken[key];

    return parsedToken;
}

function setToken(auth) {
    localStorage.setItem(AUTH_KEY, JSON.stringify(auth));
}

function removeToken() {
    localStorage.removeItem(AUTH_KEY);
}

export function hasAuthToken() {
    return !!getToken();
}

decorate(AppStore, {
    isAuthenticated: observable,
    isReady: computed,
    user: observable,
    requestError: observable,
    loginError: observable,
    init: action.bound,
    login: action.bound,
    refresh: action.bound,
    logout: action.bound,
    getDirectories: action.bound,
    getDirectory: action.bound,
    directory: observable.deep,
});
