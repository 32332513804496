export const ru = require('./ru.json');
export const en = require('./en.json');


export default {
  ns: ['page'],
  // defaultNS: 'page',
  resources: {
    en: {

    },
    ru: ru
  },
  lng: 'ru',
  fallbackLng: 'en',

  interpolation: {
    escapeValue: false
  }
};
