import httpRpc from '../../lib/api';
import {decorate, observable} from 'mobx';

class RecordStore {
    lastSaveStatus = {
        status: null,
        info: null
    };
    loading = null;
    data = null;

    setLastSaveStatus(status, info) {
        this.lastSaveStatus = {
            status: status,
            info: info
        };
    }

    async read(id) {
        this.data = (await this.request('get.sip_trunk', {
            id: id
        })).data;
    }

    async update(data, formActions) {
        if (!this.data.is_transit_available) {
            delete data.is_transit;
            delete data.route_ids;
        }
        try {
            await this.request('update.sip_trunk', {
                id: this.data.id,
                app_id: this.data.app_id,
                data: data
            });
            Object.assign(this.data, data);
            this.setLastSaveStatus('success');
        } catch(e) {
            this.setLastSaveStatus('error', e.message);
        } finally {
            formActions.setSubmitting(false);
        }
    }

    async request(operation, params) {
        return await httpRpc(operation, params);
    }
}

decorate(RecordStore, {
    loading: observable,
    lastSaveStatus: observable.deep,
    data: observable.deep
});

export default RecordStore;
