import { decorate, observable } from 'mobx';
import BaseStore from './BaseStore';
import { baseUrl } from '../api/index';

export const defaultParams = {
    limit: 50,
    offset: 0,
    sort: null,
    filter: null
};

// стор для отчетов, хранит items и metadata,
// умеет сортировку, пагинацию, фильтрацию
export class BaseReportStore extends BaseStore {

    params = {
        ...defaultParams
    }

    items = []
    metadata = {}

    clear() {
        this.items = [];
        this.params = { ...defaultParams };
        this.metadata = {};
    }

    async load(params, dataKey = null) {
        try {
            const res = await super.load(params);
            this.items = dataKey ? res.data[dataKey] || [] : res.data;
            this.metadata = res.metadata;
            return res;
        } catch (e) {
            console.log('BaseReportStore load error');
        }
    }

    async exportCsv(params) {
        try {
            const res = await super.load(params);
            window.open(baseUrl + res.url.slice(1), '_blank');
        } catch (e) {
            console.log('export csv error');
        }
    }

    setFilter(name, value) {
        // debugger;
        if (!this.params.filter) {
            this.params.filter = [];
        }
        let filter = this.params.filter;
        const filterIndex = filter.findIndex(f => f.field === name);

        if (value) {
            const filterValue = { field: name, value: value, operator: 'eq' };
            filterIndex === -1 ? filter.push(filterValue) : filter[filterIndex] = filterValue;
        } else {
            if (filterIndex !== -1) {
                filter.splice(filterIndex, 1);
            }
        }
    }


}

decorate(BaseReportStore, {
    items: observable.shallow,
    metadata: observable.shallow,
});

export default BaseReportStore;
