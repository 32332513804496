import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import { BrowserRouter as Router } from 'react-router-dom';
import { Router } from 'react-router-dom';
import history from './history';
import stores from './stores';
import { Provider } from 'mobx-react';
import {ConfigProvider} from 'antd';
import ruRU from 'antd/lib/locale-provider/ru_RU';
import moment from 'moment';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18nconfig from './lib/i18n';
import 'moment/locale/ru';
import ru from './components/i18n/ru.json';
moment.locale('ru');

i18n
    .use(initReactI18next)
    .init(i18nconfig);

i18n.addResourceBundle('ru', 'common', ru);

if (process.env.NODE_ENV === 'development') {
    window.stores = stores;
}

window.isGodMode = window.location.search.indexOf('godmode') !== -1;


ReactDOM.render(
    <Provider {...stores}>

        <Router history={history}>
            <ConfigProvider locale={ruRU}>
                <App />
            </ConfigProvider>
        </Router>

    </Provider>,
    document.getElementById('root'));

