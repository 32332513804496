import moment from 'moment';

const createDateBoundary = ({name, index, getDefaultValue}) => {
    const format = 'YYYY-MM-DD HH:mm:ss',
        toString = (date, values) => date && values && (values[name] = date.format(format)),
        toMoment = values => values && moment(values[name], format),
        nowOrEarlier = date => date > moment() ? getDefaultValue() : date;

    return {
        fixRequestParams: values =>
            toString(nowOrEarlier(values && values[name] ? toMoment(values) : getDefaultValue()), values),
        fixRange: range => range && (range[index] = nowOrEarlier(range[index])),
        fromRangeToRequestParams: values => toString(moment(values.event_date_range[index]), values),
        fromRequestParamsToRange: values => (values.event_date_range[index] = toMoment(values))
    };
};

const boundaries = [createDateBoundary({
    name: 'date_from',
    index: 0,
    getDefaultValue: () => moment().set({
        hour: 0,
        minute: 0,
        second: 0
    })
}), createDateBoundary({
    name: 'date_till',
    index: 1,
    getDefaultValue: () => moment()
})];

const DateRange = {};

Object.keys(boundaries[0]).forEach(methodName => (DateRange[methodName] = values =>
    (boundaries.forEach(boundary => boundary[methodName](values)), values)));

const {fromRequestParamsToRange} = DateRange;

DateRange.fromRequestParamsToRange = values => {
    values.event_date_range = [];
    fromRequestParamsToRange(values);
};

export default Object.freeze(DateRange);
