import moment from 'moment';
import {dateFormat} from '../../lib/utils';
import BaseReportStore2WithLocalPagingAndSorting from '../../lib/stores/BaseReportStore2WithLocalPagingAndSorting';
import {decorate, observable} from 'mobx';
import {baseUrl} from '../../lib/api';

const defaultFilterParams = {
    date_from: moment().startOf('month').format(dateFormat),
    date_till: moment().format(dateFormat),
    counteragent_contract_ids: undefined,
    route_ids: undefined,
    prefix_types: undefined,
    number_capacity_owner_ids: undefined,
    locations: undefined,
    group_by: [],
    date_precision: 'month'
};

const defaultTableParams = {
    limit: 100,
    offset: 0,
    sort: null
};


class RoutesStatisticsStore extends BaseReportStore2WithLocalPagingAndSorting {
    hasTotalRow = false;
    dataType = 'duration';

    constructor(props) {
        super(props);

        this.method = 'get.routes_statistics';
        this.defaultReportParams = {
             filterParams: {...defaultFilterParams},
             tableParams: {...defaultTableParams}
        };
        this.reportParams.filterParams = {...defaultFilterParams};
        this.reportParams.tableParams = {...defaultTableParams};
    }

    getExportCsvParams() {
        let params = super.getExportCsvParams();
        params['data_type'] = this.dataType;
        return params;
    }
}

decorate(RoutesStatisticsStore, {
    dataType: observable
});

export default  RoutesStatisticsStore;
