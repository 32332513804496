import { Modal } from 'antd';
import moment from 'moment';

export const userTimeFormat = 'HH:mm:ss';
export const dateFormat = 'YYYY-MM-DD';
export const userDateFormat = 'DD.MM.YYYY';
export const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
export const userDateTimeFormat = 'DD.MM.YYYY HH:mm:ss';

// export const yesterday = moment(new Date((+Date.now() - 24*3600*1000))).format(dateFormat);
// export const today = moment().format(dateFormat);

export const yesterdayYmd = moment().day(0).format(dateFormat);
export const todayYmd = moment().format(dateFormat);

export function makeNestedRoute(match, ...nested) {
    if (!match) {
        throw new Error('match obj is required');
    }
    let path = match.path;
    if (path[path.length - 1] === '/') {
        path = path.slice(0, -1);
    }
    return path + '/' + nested.join('/');
}

export function showConfirm(title, onOk) {
    Modal.confirm({
        title,
        onOk,
        onCancel() {},
    });
}

export function showError(title, content) {
    Modal.error({
        title: title,
        content: content,
    });
}

export function sliceArr(arr) {
    return arr ? arr.slice() : [];
}

export const renderDate = t => date =>
    date ? (moment(date).format('DD.MM.YYYY') + ' ' + t('in') + ' ' + moment(date).format('HH:mm')) : '';

export const parseArrayInCurlyBraces = value => (value ? (
    `${value}`.length > 2 &&
    `${value}`.indexOf('{') == '0' &&
    `${value}`.indexOf('}') == (`${value}`.length - 1) ?
        `${value}`.slice(1, `${value}`.length - 1).split(',') : []
) : []).sort();

export { getCookie, setCookie, deleteCookie } from './cookie';

export { pluralizeRu, unitsRu } from './pluralize';

export const isOctella = () => {
    return window.location.host.includes('octella') || window.location.host.includes('usa1') || window.localStorage.getItem('__isOctella');
};
