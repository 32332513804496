import BaseReportStore2 from '../../lib/stores/BaseReportStore2';

export const partnerAccountCrm = !'$REACT_APP_PARTNER_ACCOUNT_CRM'.endsWith('REACT_APP_PARTNER_ACCOUNT_CRM') ?
    '$REACT_APP_PARTNER_ACCOUNT_CRM' : process.env.REACT_APP_PARTNER_ACCOUNT_CRM || undefined;

export const defaultFilterParams = {
    customer_id: undefined,
    search: undefined,
    crm_ext_id: undefined,
};

export default class PartnersStore extends BaseReportStore2 {
    constructor(props, limit) {
        super(props);

        this.method = 'get.partners';
        this.autoload = true;

        this.defaultReportParams = {
             filterParams: {...defaultFilterParams},
             tableParams: {
                limit,
                offset: 0,
                sort: [{field: 'id', order: 'desc'}]
            }
        };

        this.reportParams.filterParams = {...defaultFilterParams};

        this.defaultTablePagination.pageSize = limit;
        this.defaultTablePagination.pageSize = `${limit}`;

        const pageSizeOptions = [...this.defaultTablePagination.pageSizeOptions];
        !pageSizeOptions.includes(`${limit}`) && pageSizeOptions.push(`${limit}`);
        pageSizeOptions.sort((option1, option2) => option1 - option2);
        pageSizeOptions.sort((option1, option2) => option1 - option2);

        this.defaultTablePagination.pageSizeOptions = pageSizeOptions;

        this.tablePagination = {
            ...this.defaultTablePagination,
            pageSizeOptions: [...pageSizeOptions]
        };
    }

    get partnerStatuses() {
        return [
            {id: 'active', name: 'Активен'},
            {id: 'blocked', name: 'Заблокирован'},
        ];
    }

    getLoadParams() {
        return ['limit', 'offset'].reduce(
            (params, key) => ((params[key] = parseInt(params[key], 0) || 0), params),
            super.getLoadParams()
        );
    }
}
