import { BaseReportStore2 } from '../../../lib/stores/BaseReportStore2';


export const defaultFilterParams = {

    date_from: undefined,
    date_till: undefined,

    numb: undefined,
    app_id: undefined,
    customer_id: undefined,

    prefix: [],
    service_type: [],
    owner: []

};


export default class DktNumbersHistoryStore extends BaseReportStore2 {

    constructor(props) {
        super(props);

        this.method = 'get.number_pool_history';
        this.defaultReportParams.filterParams = {...defaultFilterParams};
        this.reportParams.filterParams = {...defaultFilterParams};
        this.defaultTablePagination.withoutTotal = true;
    }

}
