import { BaseReportStore2 } from '../../lib/stores/BaseReportStore2';
import httpRpc from '../../lib/api';

export const defaultFilterParams = {
    start_date_range: undefined,
    end_date_range: undefined,
    app_id: undefined,
    app_name: undefined,
    tp_type_group: undefined,
    tp_name: undefined,
    state: undefined
};

export default class TrialStore extends BaseReportStore2 {

    constructor(props) {
        super(props);

        this.method = 'get.app_trials';
        this.defaultReportParams.filterParams = {...defaultFilterParams};
        this.reportParams.filterParams = {...defaultFilterParams};
        this.defaultTablePagination.withoutTotal = true;
    };

    async update(rec, expire_date) {
        await httpRpc('update.app_trial', {
            app_id: rec.app_id,
            expire_date: expire_date,
            op_id: rec.tp_type_group === 'option' ? rec.tp_id : null
        });
    }
}
