import React, {Fragment} from 'react';
import {Icon, Menu} from 'antd';
import {Link} from 'react-router-dom';
import * as PropTypes from 'prop-types';
import './WestMenu.less';

class MainMenu extends React.Component {
    render() {
        const {menu, allowedKeys, selectedKey, t} = this.props;
        const selectedOrOpenKeys = this.getSelectedAndOpenKeys(menu, selectedKey);

        return (
            <Menu
                theme='dark'
                mode='inline'
                defaultOpenKeys={selectedOrOpenKeys}
                defaultSelectedKeys={selectedOrOpenKeys}
            >
                {menu.map(item => this.renderMenuItem(item, t, allowedKeys))}
            </Menu>
        );
    }

    getSelectedAndOpenKeys(items, key) {
        let selectedAndOpenKeys = [];

        items.map(item => {
            if (item.key === key) {
                selectedAndOpenKeys.push(item.key);
            }
            if (item.items) {
                const itemKeys = this.getSelectedAndOpenKeys(item.items, key);
                if (itemKeys.length) {
                    selectedAndOpenKeys.push(item.key, ...itemKeys);
                }
            }
        });

        return selectedAndOpenKeys;
    }

    renderMenuItem = (item, t, allowedKeys, isSub) => {
        const {key, icon, items} = item,
            label = t(`menu.${key}`);

        if (items) {
            const title = (
                    <Fragment>
                        <Icon type={icon || 'no-icon'}/>
                        <span>{label}</span>
                    </Fragment>
                ),
                subItems = items.map(item => this.renderMenuItem(item, t, allowedKeys, true));

            if (!subItems.filter(item => item !== '').length) {
                return '';
            }

            return (
                <Menu.SubMenu key={key} title={title}>
                    {subItems}
                </Menu.SubMenu>
            );
        }

        if (!allowedKeys.includes(key)) {
            return '';
        }

        return (
            <Menu.Item key={key}>
                <Link tag='span' to={`/${key}`}>
                    {!isSub && <Icon type={icon || 'no-icon'}/>}
                    <span>{label}</span>
                </Link>
            </Menu.Item>
        );
    }
}

export default MainMenu;

MainMenu.propTypes = {
    menu: PropTypes.array.isRequired,
    allowedKeys: PropTypes.array.isRequired,
    selectedKey: PropTypes.string,
    t: PropTypes.func.isRequired
};
