import { BaseReportStore2 } from '../../lib/stores/BaseReportStore2';
import {yesterdayYmd, dateFormat} from '../../lib/utils';
import moment from 'moment';

export const defaultFilterParams = {
    date_from: moment().day(-6).format(dateFormat),
    date_till: yesterdayYmd,
    app_id: undefined,
    domain: undefined,
};

export default class Store extends BaseReportStore2 {

    constructor(props) {
        super(props);

        this.method = 'get.robot_ac_summary';
        this.defaultReportParams.filterParams = {...defaultFilterParams};
        this.reportParams.filterParams = {...defaultFilterParams};
        this.defaultTablePagination.withoutTotal = true;
    };
}
