import { decorate, observable, action, computed } from 'mobx';
import httpRpc from '../../lib/api';

export class PartnerStore {

    url = 'partner';
    loading = false;

    partnerData = {};

    get mainData() {
        return {
            id: this.partnerData.id || null,
            name: this.partnerData.name,
            infopin_customer_id: this.partnerData.infopin_customer_id || null,
            crm_ext_id: this.partnerData.crm_ext_id || null,
            email: this.partnerData.email || null,
            locale: this.partnerData.locale || null
        };
    }

    isVisibleAddPartnerModal = false;
    setIsVisibleAddPartnerModal = val => this.isVisibleAddPartnerModal = val;


    constructor(stores) {
        this.stores = stores;
    }

    async createPartner(data, formActions) {
        try {
            const res = await this.req('create', this.url, {data});
            this.setIsVisibleAddPartnerModal(false);
            return res.data;
        } catch (e) {
            return null;
        }
    }

    async req(method, url, params) {
        const operation = `${method}.${url}`;
        try {
            const res = await httpRpc(operation, params);
            return res;
        } finally {
            this.loading = false;
        }
    }

}

decorate(PartnerStore, {
    loading: observable,
    entity: observable,
    formsValidationInfo: observable,
    partnerData: observable.deep,
    mainData: computed,
    isVisibleAddPartnerModal: observable.deep,
    setIsVisibleAddPartnerModal: action.bound
});

export default PartnerStore;
