import httpRpc from '../../../lib/api';
import { BaseReportStore2 } from '../../../lib/stores/BaseReportStore2';

export const defaultFilterParams = {
    start_date: undefined,
    till_date: undefined,
    app_id: undefined,
    app_name: undefined,
    status: undefined,
    sum: undefined,
};

export default class PromisedPaymentStore extends BaseReportStore2 {
    constructor(props) {
        super(props);

        this.method = 'novofon.get.promised_payments';
        this.defaultReportParams.filterParams = { ...defaultFilterParams };
        this.reportParams.filterParams = { ...defaultFilterParams };
        this.defaultTablePagination.withoutTotal = true;
    }

    async update({ id, sum, till_date }) {
        await httpRpc('novofon.update.promised_payment', {
            id,
            sum,
            till_date,
        });
    }
}
