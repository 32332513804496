import { decorate, observable, action, computed } from 'mobx';
import httpRpc from '../../lib/api';

const envIsAlternativeClientRegistration = !'$REACT_APP_IS_ALTERNATIVE_CLIENT_REGISTRATION'.endsWith('REACT_APP_IS_ALTERNATIVE_CLIENT_REGISTRATION') ?
    '$REACT_APP_IS_ALTERNATIVE_CLIENT_REGISTRATION' : process.env.REACT_APP_IS_ALTERNATIVE_CLIENT_REGISTRATION || undefined;


export class ClientStore {

    url = 'app';
    loading = false;

    numbAsNumaSelectionData = [
        {name: 'Номер звонящего', value: 0},
        {name: 'Номер услуги', value: 1}
    ];

    clientData = {}; // app
    bannerBrandingData = []; // site
    dtBlackIds = []; // dt_black_ip
    softphoneSettingsData = [];

    availableTariffPlans = []; // tp
    selectedContractTemplateId = null;

    isAlternativeClientRegistration = envIsAlternativeClientRegistration !== undefined
        ? JSON.parse(envIsAlternativeClientRegistration)
        : [
            'admin-app.callgear.com',
            'admin-app.callgear.ae',
            'admin-app.octella.com'
        ].includes(window.location.hostname);

    get domainProject() {
        const hostname = window.location.hostname;
        const localStorageDomainProject = localStorage.getItem('domainProject-4waF3v');
        const availableProjects = ['usa', 'rolands'];
        if (hostname.includes('.uis.st') && availableProjects.includes(localStorageDomainProject)) {
            return localStorageDomainProject;
        }
        let project = this.isAlternativeClientRegistration && {
            'admin-app.callgear.com': 'usa',
            'admin-app.callgear.ae': 'usa',
            'admin-app.octella.com': 'rolands'
        }[hostname];
        project = project || 'usa';
        return project;
    }

    get tpSelectionData() {
        return this.availableTariffPlans.concat([{ id: this.clientData.tp_id, name: this.clientData.tariff_plan }]);
    }

    get mainData() {
        return {
            app_name: this.clientData.app_name,
            app_id: this.clientData.app_id || null,
            customer_id: this.clientData.customer_id || null,
            transit_dst_app_id: this.clientData.transit_dst_app_id || null,
            is_use_numb_as_numa: this.clientData.is_use_numb_as_numa || null,
            force_logout_time: this.clientData.force_logout_time || null,
            hide_return_commission: this.clientData.hide_return_commission || false,
            tp_id: this.clientData.tp_id,
            agent_id: this.clientData.agent_id || null,
            is_agent: this.clientData.is_agent,
            is_share_tp: this.clientData.is_share_tp,
            is_short_phone_shared_in_holding: this.clientData.is_short_phone_shared_in_holding,
            rtu: this.clientData.rtu,
            project: this.clientData.project,
            is_buy_number_capacity_disabled: this.clientData.is_buy_number_capacity_disabled,
            is_uae_restriction_enabled: this.clientData.is_uae_restriction_enabled,
            is_ru_restriction_enabled: this.clientData.is_ru_restriction_enabled,
            shared_short_phone_app_ids: this.clientData.shared_short_phone_app_ids ?
                this.clientData.shared_short_phone_app_ids.map(v => v.toString()) : [],
            is_load_phone_book_contacts: this.clientData.is_load_phone_book_contacts,
            date_start: this.clientData.date_start,
        };
    }

    isVisibleAddClientModal = false;
    setIsVisibleAddClientModal = val => this.isVisibleAddClientModal = val;


    constructor(stores) {
        this.stores = stores;
    }

    setContractTemplateId(contractTemplateId) {
        this.selectedContractTemplateId = contractTemplateId;
    }

    getContractTemplateCurrencies(contractTemplateId) {
        return contractTemplateId
            ? this.stores.appStore.getDirectory('infopin:public:basic_contract_templates')
                .find(item => item.id === contractTemplateId)['counteragent_bank_requisite_currency_list']
                .map(item => ({id: item, name: item}))
            : [];
    }

    async createCustomer({name, email, tariff, phone, contract}, formActions) {
        const res = await this.req('create', this.url, {
            customer_name: name,
            email,
            tariff_plan_id: tariff,
            phone,
            contract_id: contract
        });
        this.setIsVisibleAddClientModal(false);
        return res.data;
    }

    async getCustomerData(clientId) {
        const res = await this.req('get', this.url, {app_id: clientId});
        this.clientData = res.data['app'];
        this.bannerBrandingData = res.data['site'];
        this.dtBlackIds = res.data['dt_black_ip'];
        this.availableTariffPlans = res.data['tp'];
        this.softphoneSettingsData = res.data['softphone_settings'];
    }

    updateMainParams(appParams, formActions) {
        const params = {app: {}, acrobits: {}};
        if (this.mainData.is_agent) {
            params.app.hide_return_commission = appParams.hide_return_commission;
            if (this.mainData.is_share_tp) {
                params.app.is_short_phone_shared_in_holding = appParams.is_short_phone_shared_in_holding;
            }
        } else {
            params.app.is_use_numb_as_numa = !!appParams.is_use_numb_as_numa;
            params.app.force_logout_time = appParams.force_logout_time;
            if (appParams.transit_dst_app_id !== (this.clientData.transit_dst_app_id || null)) {
                params.app.transit_dst_app_id = appParams.transit_dst_app_id || null;
            }
            let shared_short_phone_app_ids = appParams.shared_short_phone_app_ids.length ?
                    appParams.shared_short_phone_app_ids.map(v => parseInt(v)) : null,
                server_shared_short_phone_app_ids = this.clientData.shared_short_phone_app_ids ?
                    this.clientData.shared_short_phone_app_ids.toJS() : null;
            if (JSON.stringify(shared_short_phone_app_ids) !== JSON.stringify(server_shared_short_phone_app_ids)) {
                params.app.shared_short_phone_app_ids = shared_short_phone_app_ids;
            }
            if (appParams.tp_id && appParams.tp_id !== this.clientData.tp_id) {
                params.tp_id = appParams.tp_id;
            }
            if (appParams.rtu && appParams.rtu !== this.clientData.rtu) {
                params.rtu = appParams.rtu;
            }
            if (appParams.is_buy_number_capacity_disabled !== this.clientData.is_buy_number_capacity_disabled) {
                params.is_buy_number_capacity_disabled = appParams.is_buy_number_capacity_disabled;
            }
            if (appParams.is_uae_restriction_enabled !== this.clientData.is_uae_restriction_enabled) {
                params.is_uae_restriction_enabled = appParams.is_uae_restriction_enabled;
            }
            if (appParams.is_ru_restriction_enabled !== this.clientData.is_ru_restriction_enabled) {
                params.is_ru_restriction_enabled = appParams.is_ru_restriction_enabled;
            }
            if (appParams.is_load_phone_book_contacts !== this.clientData.is_load_phone_book_contacts) {
                params.acrobits.is_load_phone_book_contacts = appParams.is_load_phone_book_contacts;
            }
        }
        return this.updateCustomerData(params, formActions);
    }

    updateBannerBrandingParams(bannerBrandingParams, formActions) {
        return this.updateCustomerData({
            site: bannerBrandingParams
        }, formActions);
    }

    updateDtBlackIp(dtBlackIpParams, formActions) {
        return this.updateCustomerData({
            dt_black_ip: dtBlackIpParams
        }, formActions);
    }

    async updateCustomerData(params) {
        await this.req('update', this.url, Object.assign({
            app_id: this.mainData.app_id,
            customer_id: this.mainData.customer_id
        }, params));
    }

    async updateSoftphoneSettings(softphoneSettingsParams, formActions) {
        return this.updateCustomerData({
            softphone_settings: softphoneSettingsParams
        }, formActions);
    }

    async deleteCallCenterSystemSettings(params) {
        await this.req('delete', 'call_center_system_settings', {
            app_id: this.mainData.app_id,
            ...params
        });
    }

    async req(method, url, params) {
        const operation = `${method}.${url}`;
        try {
            const res = await httpRpc(operation, params);
            return res;
        } finally {
            this.loading = false;
        }
    }

}

decorate(ClientStore, {
    loading: observable,
    entity: observable,
    formsValidationInfo: observable,
    clientData: observable.deep,
    mainData: computed,
    bannerBrandingData: observable,
    dtBlackIds: observable,
    isVisibleAddClientModal: observable.deep,
    setIsVisibleAddClientModal: action.bound,
    numbAsNumaSelectionData: observable,
    availableTariffPlans: observable,
    tpSelectionData: computed,
    softphoneSettingsData: observable,
    isAlternativeClientRegistration: observable,
    domainProject: computed,
});

export default ClientStore;
