import { BaseReportStore2 } from '../../../lib/stores/BaseReportStore2';

export const defaultFilterParams = {
    ip_address: undefined,
    location: 'ru'
};

export default class CheckIpStore extends BaseReportStore2 {

    constructor(props) {
        super(props);

        this.method = 'get.geoip_location';
        this.defaultReportParams.filterParams = {...defaultFilterParams};
        this.reportParams.filterParams = {...defaultFilterParams};
        this.defaultTablePagination.withoutTotal = true;
    };
}
