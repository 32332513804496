import { decorate, observable, } from 'mobx';
import httpRpc from '../api';


// базовый стор, умеет вызывать рпс по методу и параметрам
export class BaseStore {


    loading = false
    method = null

    constructor(stores) {
        this.stores = stores;

    }

    params = {}  // после рефакторинга (когда все отчеты будут переведены на BaseStore2) снести нах

    setParams(params) {
        return this.params = {
            ...this.params,
            ...params
        };
    }

    processParams(params) {
        return params;
    }

    async load(params) {
        this.loading = true;
        params = (!params || !params.export) ? this.setParams(params) : params;

        try {
            return await httpRpc(this.method, this.processParams(params));
        } catch (e) {
            console.log('store load error');
            throw e;
        } finally {
            this.loading = false;
        }
    }
}

decorate(BaseStore, {
    loading: observable,
});

export default BaseStore;
