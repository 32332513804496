import { BaseReportStore2 } from '../../../lib/stores/BaseReportStore2';
import { todayYmd, yesterdayYmd } from '../../../lib/utils';


export const defaultFilterParams = {

    date_from: yesterdayYmd,
    date_till: todayYmd,

    numa: undefined,
    numb: undefined,
    app_id: undefined

};


export default class DktNumbersCallsHistoryStore extends BaseReportStore2 {

    constructor(props) {
        super(props);

        this.method = 'get.number_pool_calls_history';
        this.defaultReportParams.filterParams = {...defaultFilterParams};
        this.reportParams.filterParams = {...defaultFilterParams};
        this.defaultTablePagination.withoutTotal = true;
    }

}
