import { defaultParams, BaseReportStore } from '../../lib/stores/BaseReportStore';
import httpRpc from '../../lib/api';

export default class CallsStore extends BaseReportStore {
    method = 'get.calls'

    params = {
        ...defaultParams,
        filter: []
    }

    async getCallSessionDetails(id) {
        return await httpRpc('get.call_details', {
            app_id: this.params.app_id,
            call_session_id: id,
        });
    }

}


